import {timeFormat, numberFormat} from "@custom";

export const tableColumn = [
    {
        type: "index",
        label: "序号",
        width: 100
    },
    {
        prop: "transactionId",
        label: "订单号",
        width: 250,
    },
    // {
    //     prop: "merchantName",
    //     label: "商户号简称",
    //     // width: 100
    // },
    {
        prop: "name",
        label: "用户名",
        // width: 100
    },
    {
        prop: "statusEnum",
        label: "状态",
        width: 100,
        render: (h, {row}) => {   //created=生成账单, paid==支付, invoice==生成发票,fail==失败,order==订单,refund==退款,part==部分退款
            let statusName = "";
            let {statusEnum} = row;
            switch (statusEnum) {
                case "created":
                    statusName = "待支付";
                    break;
                case "paid":
                    statusName = "支付";
                    break;
                case "invoice":
                    statusName = "生成发票";
                    break;
                case "fail":
                    statusName = "失败";
                    break;
                case "order":
                    statusName = "订单";
                    break;
                case "refund":
                    statusName = "退款";
                    break;
                case "part":
                    statusName = "部分退款";
                    break;
            }
            return h("span", statusName);
        },
    },
    {
        prop: "channelName",
        label: "渠道",
        // width: 100,
    },
    {
        prop: "type",
        label: "类型",
        // width: 100,
    },
    {
        prop: "total",
        label: "充值金额(元)",
        width: 150,
        render: (h, {row}) => {
            return h("span", !row.total ? "" : numberFormat(row.total / 100));
        },
    },
    // {
    //     label: "手续费",
    //     width: 150,
    //     render: (h, {row}) => {
    //         let {total = 0, recordedAmount = 0} = row;
    //         return h("span", numberFormat((total - recordedAmount) / 100));
    //     },
    //     renderHeader: (h, {column, $index}) => {
    //         return h(
    //             "el-tooltip",
    //             {
    //                 props: {
    //                     content: "手续费=充值金额*0.2%+充值金额*0.4%",
    //                     placement: "top",
    //                     effect: "light",
    //                 },
    //                 //最后展示的内容是 icon 图标 + 列的 label
    //                 domProps: {
    //                     innerHTML: `${column.label} <i class="el-icon-question"/>`,
    //                 },
    //             }, [h("span")]
    //         );
    //     }
    // },
    // {
    //     prop: "recordedAmount",
    //     label: "入账金额(元)",
    //     // width: 150,
    //     render: (h, {row}) => {
    //         return h("span", !row.recordedAmount ? "" : numberFormat(row.recordedAmount / 100));
    //     },
    // },
    // {
    //     prop: "recordedAmount",
    //     label: "入账金额(元)",
    //     // width: 150,
    //     render: (h, {row}) => {
    //         return h("span", !row.recordedAmount ? "" : numberFormat(row.recordedAmount / 100));
    //     },
    // },
    {
        prop: "createTime",
        label: "充值时间",
        // width: 150,
        render: (h, {row}) => {
            return h("span", !row.createTime ? "" : timeFormat(new Date(Number(row.createTime)), 'yyyy-MM-dd HH:mm:ss'));
        },
    },

];
